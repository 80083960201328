@import "../../Assets//Styles/variables";

.space-page {
    --fsTit: 54px;
    --paddingTitle: 100px 0 80px 0;
    --paddingStatus: 32px 0 72px;
    --rowGap: 100px;
    --fsDetail: 17.5px;
    --paddingDetail: 12px 0 16px;
    --fsDetailImg: 24px;
    --fsTitleHover: 39.5px;
    --spaceHover: 40px;
    width: 100%;
    padding: 0;
    .space-title {
        padding: var(--paddingTitle);
        text-align: center;

        h2 {
            text-transform: uppercase;
            font-size: var(--fsTit);
            font-family: $arialMTBold;
        }
    }

    .space-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        background-color: #000;
        &-item {
            .item-content {
                font-size: var(--fsDetail);

                .item-img {
                    cursor: pointer;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }

                    &::before {
                        background-color: rgba(256, 256, 256, 0.888);
                        transition: 400ms all;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 0;
                        height: 0;
                        z-index: 99;
                    }

                    .arrow-right {
                        position: absolute;
                        top: 7%;
                        left: 7%;
                        transition: 400ms all;
                        z-index: 1000;
                        display: none;

                        img {
                            width: 60px;
                            height: 60px;
                        }
                    }

                    .content-img {
                        position: absolute;
                        top: 5%;
                        left: 7%;
                        transition: 400ms all;
                        z-index: 1000;
                        display: none;
                        font-family: $notoSans;

                        .title {
                            font-size: var(--fsTitleHover);
                            padding-bottom: var(--spaceHover);
                            white-space: pre-line;
                        }

                        p {
                            white-space: pre-line;
                            line-height: 1.5;
                            font-size: var(--fsDetailImg);
                        }
                    }

                    .list-status {
                        position: absolute;
                        bottom: 5%;
                        right: 2%;
                        z-index: 3333;
                        display: flex;

                        .button-status {
                            background: #fff;
                            border: 1px solid #111;
                            padding: 10px 15px;
                            margin-right: 10px;
                        }

                        .darkStatus {
                            background-color: #111;
                            color: #fff;
                        }
                    }

                    &:hover {
                        &::before {
                            width: 100%;
                            height: 100%;
                        }

                        .arrow-right,
                        .content-img {
                            display: block;
                        }
                    }
                }


            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .space-page {
        --fsTitleHover: 24px;
        --fsDetailImg: 18px;

        .space-list {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media screen and (max-width: 880px) {
    .space-page {
        --fsTitleHover: 20px;
        --fsDetailImg: 14px;
        --spaceHover: 20px;

        .space-list {
            grid-template-columns: 1fr;

            .space-list-item {

                .space-list {
                    background-color: #fff;
                }
                .item-img {
                    .list-status {
                        .button-status {
                            padding: 5px 10px;
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 660px) {}

@media screen and (max-width: 500px) {
    .space-page {
        --fsTitleHover: 16px;
        --fsDetailImg: 10px;
        --spaceHover: 10px;
   
    }
}