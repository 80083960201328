@import "../../../Assets/Styles/styles.scss";
.artService {    
    --fsSubtitle: 24px;
    --fsTitle: 54px;
    --fsExtend: 24px;
    --paddingSection: 56px 40px;
    border-bottom: 1px solid #111;

    .infoSection {
        .subtitle {
            font-size: var(--fsSubtitle); 
            font-family: $arialMT;
        }
        .title {
            display: flex;
            align-items: flex-end;
            padding-top: 18px;
            h2 {
                font-size: var(--fsTitle);
                font-family: $arialMTBold;
                line-height: 1;
            }
            .extend {
                font-size: var(--fsExtend);
                font-family: $notoSans;
                padding-left: 40px;
                line-height: 1.6;
            }
        }
    }
    .mediaArt, .viewMore {
        padding: var(--paddingSection);
    }
    .wallMuseum {
        position: relative;
        background-image: url(../../../Assets/Images/artservice.png);
        height: 200px;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
       background-color: #111;
       background-position: right;
       .info {
        padding: var(--paddingSection);
       }
    }
    @media screen and (max-width: 880px) {
        --paddingSection: 56px 15px;
        --paddingMain: 40px 28px;
        --fsSubtitle: 24px;
        --fsTitle: 30px;
        --fsExtend: 24px;
        .infoSection{
            .title{
                display:flex;
                flex-direction: column;
                h2{
                    width: 100%;
                }
                .extend{
                    width: 100%;
                    padding-left: unset;
                }
            }
        }
    }

    // @media screen and (max-width: 660px) {
    //     --fsSubtitle: 20px;
    //     --fsTitle: 24px;
    //     --fsExtend:24px;

    // }

    // @media screen and (max-width: 500px) {
    //     --fsSubtitle: 14px;
    //     --fsTitle: 20px;
    //     --fsExtend: 14px;
    // }
}