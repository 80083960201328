@import "../../../Assets/Styles/variables";

.banner {
    --leftDevistion: 23%;
    --fsTitle: 54px;
    --fsDetail: 18px;
    --fsSubtitle: 36px;
    --padding: 19px 31px;
    --paddingDetail: 10px 31px;
    background-color: #070707;
    position: relative;
    color: #fff;

    img {
        width: 100%;
        // max-height: 1080px;
        object-fit: contain;
    }

    &-title {
        position: absolute;
        bottom: 30%;
        left: var(--leftDevistion);
        z-index: 40;

        .title {
            background-color: #010101;
            padding: var(--padding);
            display: inline-block;

            h2 {
                font-size: var(--fsTitle);
                color: #fff;
                font-family: $arialMTBold;
            }
        }

        .subtitle {
            background-color: #010101;
            font-size: var(--fsSubtitle);
            font-family: $notoSans;
            padding: var(--padding);
            margin-top: 4px;
            font-weight: bold;
        }
    }

    &-detail {
        position: absolute;
        bottom: 5%;
        left: var(--leftDevistion);
        z-index: 40;
        padding: var(--paddingDetail);
        font-size: var(--fsDetail);
        font-family: $notoSansLight;
        line-height: 1.7;
        background-color: #070707;
    }

    @media screen and (max-width: 1023px) {
        --leftDevistion: 5%;
        --fsTitle: 40px;
        --fsSubtitle: 24px;
        --fsDetail: 16px;
        --padding: 14px 20px;

        .banner-title {
            bottom: 40%
        }
    }

    @media screen and (max-width: 767px) {
        --fsTitle: 30px;
        --fsSubtitle: 16px;
        --fsDetail: 14px;
        --padding: 10px 14px;
        --paddingDetail: 8px 12px;

        img {
            height: 400px;
        }

        .banner-title {
            bottom: 35%
        }
    }
}