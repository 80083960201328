// Montserrat
@import "./variables.scss";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-tab-highlight-color: transparent;
  user-select: none;
  // @include family--montserrat;
}

body {
  font-family: $notoSans;
}

::-webkit-scrollbar {
  display: none;
}
ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #111;
}

video {
  max-width: 100%;
}

img {
  max-width: 100%;
}

