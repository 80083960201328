@import "../../../Assets/Styles/styles.scss";

.commercial {
    --paddingMain: 50px 0px 60px 30px;
    --fsSubtitle: 24px;
    --fsTitle: 54px;
    --fsDate: 35.5px;
    --paddingTitle: 30px 0 90px;
    --fsMore: 18px;
    --fsDetail: 16px;
    --spacingSlide: 130px;

    border-bottom: 1px solid #111;
    font-family: $arialMT;

    &-container {
        padding: var(--paddingMain);

        .intro {
            margin-bottom: 130px;
            &-title {
                .subtitle {
                    font-size: var(--fsSubtitle);
                }

                .title {
                    padding: var(--paddingTitle);

                    h2 {
                        font-size: var(--fsTitle);
                        line-height: 1.2;
                        font-family: $arialMTBold;
                    }

                    .date {
                        font-size: var(--fsDate);
                        padding-top: 25px;
                        font-family: $notoSans;
                    }
                }

                .more {
                    a {
                        font-size: var(--fsMore);
                        text-decoration: underline;
                        font-family: $arialMTBold;
                    }
                }
            }

            &-detail {
                padding-right: 10%;
                display: flex;
                justify-content: space-between;

                p {
                    font-size: var(--fsDetail);
                    line-height: 1.7;
                    font-family: $notoSans;
                    opacity: 0.8;
                }
            }
        }
        .slide-box{
        .slide {
            padding-top: var(--spacingSlide);
            padding-top: 25%;
            position: relative;
            width: 100%;
            .navigation-commercial-custom {
                position: absolute;
                top: -45px;
                left: 67.4%;
                display: flex;

                .custome-item {
                    margin: 0 7px;
                    padding: 0 4px;
                    cursor: pointer;
                }

                .activePagination {
                    border-bottom: 1.9px solid #111;
                    padding-bottom: 12px;
                }
            }

            .swiper {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                .swiper-slide img {
                    width: 100%;
                    height: auto;
                }
            }

            .swiper-slide {
                // width: 60%;
                height: 420px;
            }
        }
        }
    }

    @media screen and (max-width: 880px) {
        --fsSubtitle: 20px;
        --fsTitle: 30px;
        --fsDate: 24px;
        --fsMore: 14px;
        --fsDetail: 16px;

        .commercial-container {
            .intro{
margin-bottom: 50px;
                .intro-detail {
                    
                    padding-right: 30px;
                    
                    h2 {
                        margin-right: 24px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 880px) {
        --fsSubtitle: 24px;
        --fsTitle: 40px;
        --fsDate: 24px;
        --fsMore: 24px;
        --fsDetail: 16px;
        --paddingMain: 50px 0px 60px 15px;
        .commercial-container{
            .intro{
                .intro-title{
                    .subtitle{
                      
                    }
                    .title{
                          .intro-detail{
                            display: flex;
                            flex-direction: column;
                          }  
                    }
                }
            }
        }
    }
}