@import "../../../Assets/Styles/_variables.scss";

.capability {
    --fsTit: 54px;
    --fsDetail: 18px;
    --paddingBottom: 30px;
    position: relative;
    margin-bottom: -6px;
    .mainImg {
        width: 100%;
    }
    .plus-icon {
        position: absolute;
        left: 50%;
        top: 20%;
        transform: translate(-50%, -25%);
        z-index: 99;

    }

    .equal-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%);
        z-index: 99;

    }

    .content {
        color: #fff;
        position: absolute;
        width: 1200px;
        top: 0;
        left: 50%;
        height: 100%;
        transform: translateX(-50%);
        z-index: 99;
        padding: 120px 0 0 0;

        &-detail {
            .title {
                font-size: var(--fsTit);
                font-family: $arialMTBold;
                text-transform: uppercase;
                padding-bottom: var(--paddingBottom);
                text-align: center;
            }

            .detail {
                font-size: var(--fsDetail);
                line-height: 1.7;
                text-align: center;
                font-family: $notoSansLight;
            }
        }

        .content-plus {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .content-equal {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 100%;
            bottom: 80px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    @media screen and (max-width: 1300px) {
        --fsTit: 40px;
        --fsDetail: 16px;

        .content {
            width: 100%;
            padding: 50px 25px;

            .content-equal {
                bottom: 30px
            }
        }
    }

    @media screen and (max-width: 1024px) {
        --fsTit: 30px;
        --fsDetail: 14px;
        --paddingBottom: 10px;

        .mainImg {
            height: 500px;
        }
    }

    @media screen and (max-width: 767px) {
        --fsTit: 24px;
        --fsDetail: 13px;

        .plus-icon,
        .equal-icon {
            display: none;
        }

        .content {
            padding: 30px 20px;

            .content-plus {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
            }
        }

        .content-detail {
            height: 140px;

            .detail {
                padding: 0 20px;
            }
        }
    }
}