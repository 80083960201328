@import "../../../Assets/Styles/styles.scss";

.career {    
    --fsTitle: 24px;
    --paddingTitle: 23px 30px;
    --fsContent: 18px;
    .title {
        font-family: $arialMT;
        padding: var(--paddingTitle);
        font-size: 24px;
    }
    .menu {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        &-item {
            position: relative;
            // max-height: 526px;
            img {
                filter : grayscale(100%);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &-content {
                position: absolute;
                bottom: 15px;
                left: 20px;
                color: #fff;
                font-size: var(--fsContent);
                .title-en {
                   font-family: $arialMT;
                   padding-bottom: 5px;
                }
                .title-ko {
                    font-family: $notoSans;
                }
            }
        }
    }
    @media screen and (max-width: 1300px) {
        .menu {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
}