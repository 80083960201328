@import "../../../Assets/Styles/_variables.scss";

.value {
    --fsTitle: 54px;
    --fsDetail: 18px;
    --paddingDetail: 50px;
    --marginValue: 150px auto;
    --paddingSection: 0 25px;
    --paddingBottom: 7px;

    max-width: 1250px;
    margin: var(--marginValue);
    padding: var(--paddingSection);

    &-info {
        .title {
            h2 {
                font-size: var(--fsTitle);
                font-family: $arialMTBold
            }
        }

        .detail {
            padding-top: var(--paddingDetail);
            padding-bottom: var(--paddingBottom);
            font-size: var(--fsDetail);
            line-height: 1.7;
            font-family: $notoSansLight;
        }
    }

    &-card {
        display: grid;
        grid-template-columns: 50% auto;
        column-gap: 30px;

        .title {
            font-size: 24px;
            font-family: $arialMTBold;
            padding: 30px 0;
        }

        .detail {
            font-size: var(--fsDetail);
            line-height: 1.7;
            font-family: $notoSansLight;
            // white-space: nowrap;
        }

        &-left {
            padding-top: 150px;
        }

        &-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    @media screen and (max-width: 767px) {
        --fsTitle: 30px;
        --fsDetail: 14px;
        --paddingDetail: 30px;
        --marginValue: 30px auto;
        --paddingSection: 0 18px;
        --paddingBottom: 20px;

        br {
            display: none;
        }
    }
}