@import "../../../Assets/Styles/variables";

.chapters {
    --fsTitle: 54px;
    --fsSub: 18px;
    --paddingBottom: 26px;
    --whiteSpace: pre-line;
    --bottom: 14%;
    background-color: #111;
    color: #fff;

    &-section {
        position: relative;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }

        .title {
            position: absolute;
            top: 5%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 33;

            h2 {
                font-size: var(--fsTitle);
                font-family: $arialMTBold;
                color: #fff;
            }
        }

        &-info {
            position: absolute;
            left: 50%;
            bottom: var(--bottom);
            transform: translateX(-50%);
            z-index: 33;
            font-size: var(--fsSub);
            text-align: center;
            font-family: $notoSansLight;

            .title-section {
                padding-bottom: var(--paddingBottom);
            }

            .detail-section {
                white-space: var(--whiteSpace);
                line-height: 1.7;
            }
        }

        .darkColor {
            color: #111;
        }
    }

    @media screen and (max-width: 767px) {
        --fsTitle: 34px;
        --fsSub: 14px;
        --paddingBottom: 16px;
        --whiteSpace: wrap;
        --bottom: 8%;

        img {
            height: 500px;
        }

        .darkColor {
            background-color: #fff !important;
        }
    }
}