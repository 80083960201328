@import "../../Assets//Styles/variables";

.gallery-page {
    --fsTit: 54px;
    --paddingTitle: 100px 0 80px 0;
    --paddingStatus: 32px 0 72px;
    --rowGap: 100px;
    --fsDetail: 17.5px;
    --paddingDetail: 12px 0 16px;
    --fsDetailImg: 20px;
    width: 100%;
    padding: 0 187px;

    .gallery-title {
        padding: var(--paddingTitle);
        text-align: center;

        h2 {
            text-transform: uppercase;
            font-size: var(--fsTit);
            font-family: $arialMTBold;
        }
    }

    .gallery-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 100px;
        padding-bottom: 100px;

        // column-gap: 30px;
        &-item {
            border-top: 1px solid #111;

            .item-content {
                margin: 0 15px;
                font-size: var(--fsDetail);

                .item-img {
                    cursor: pointer;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    &::before {
                        background-color: rgba(17, 17, 17, 0.888);
                        transition: 400ms all;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 0;
                        height: 0;
                        z-index: 99;
                    }

                    .arrow-right {
                        position: absolute;
                        top: 7%;
                        left: 7%;
                        transition: 400ms all;
                        z-index: 1000;
                        display: none;

                        img {
                            width: 60px;
                            height: 60px;
                        }
                    }

                    .content-img {
                        position: absolute;
                        bottom: 7%;
                        left: 7%;
                        transition: 400ms all;
                        z-index: 1000;
                        display: none;

                        p {
                            white-space: pre-line;
                            line-height: 1.5;
                            color: #fff;
                            font-size: var(--fsDetailImg);
                        }
                    }

                    &:hover {
                        &::before {
                            width: 100%;
                            height: 100%;
                        }

                        .arrow-right,
                        .content-img {
                            display: block;
                        }
                    }
                }

                .item-status {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: var(--paddingStatus);

                    .status {
                        text-decoration: underline;
                    }

                    .status-complete {
                        color: #999999;
                    }

                    .quantity {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                .item-detail {
                    .location {
                        font-family: $arialMT;
                        font-size: --fsDetail;
                        text-overflow : clip ;
                    }

                    .time {
                        font-family: $notoSans;
                        font-size: --fsDetail;
                        padding: var(--paddingDetail)
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1300px) {
        // --fsDetail: 12.5px;
        padding: 0 100px;
        .gallery-list {
            display: grid;
            grid-template-columns: 1fr 1fr ;
        }
    }
    @media screen and (max-width: 980px) {
        // --fsDetail: 10.0px;
        padding: 0 0px;
        .gallery-list {
            display: grid;
            grid-template-columns: 1fr ;
        }
    }
}