@font-face {
  font-family: "Arial-BoldMT";
  font-style: normal;
  src: url("../Font/Arial-BoldMT.otf");
}
@font-face {
  font-family: "ArialMT";
  font-style: normal;
  src: url("../Font/ARIALMT.ttf");
}

@font-face {
  font-family: "NotoSans";
  font-style: normal;
  src: url("../Font/NotoSansCJKkr-Regular.otf");
}
  
@font-face {
  font-family: "NotoSansLight";
  font-style: normal;
  src: url("../Font/NotoSansCJKkr-DemiLight.otf");
}
  
@font-face {
  font-family: "NanumBold";
  font-style: normal;
  src: url("../Font/NanumGothic-Bold.ttf");
}
@font-face {
  font-family: "NanumLight";
  font-style: normal;
  src: url("../Font/NanumBarunGothicLight.ttf");
}
  
  
  
