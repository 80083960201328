@import "../../Assets//Styles//styles.scss";

header {
    --paddingLogo: 32px 15px;
    --paddingMenu: 25px 22px;
    --fsLang: 24px;
    --fsMenu: 28px;
    --spaceMenu: 50px;
    font-family: $arialMTBold;
    width: 100%;

    .header-logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--paddingLogo);
        width: 100%;

        .logo {
            max-width: 170px;
            max-height: 46px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .lang {
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;

            img {
                width: 15px;
                height: 13px;
                object-fit: contain;
            }

            span {
                font-size: var(--fsLang);
                padding-right: 15px;
            }

            .list-lang {
                position: absolute;
                bottom: -5px;
                left: 0;
                z-index: 33;
                width: 100%;
                transform: translateY(100%);
                display: inline-block;
                padding: 6px 12px;
                margin-bottom: 0;
                text-align: center;
                white-space: nowrap;
                background-color: #fff;
                vertical-align: middle;
                cursor: pointer;
                border: 1px solid #ccc;
                border: 1px solid rgba(0, 0, 0, .15);
                border-radius: 4px;
                box-shadow: 0 6px 12px rgba(0, 0, 0, .175);

                li {
                    font-size: var(--fsLang);
                    padding: 5px 0;
                }
            }
        }
    }

    .menu {
        display: flex;
        text-transform: uppercase;
        border-top: 1px solid #111111;
        border-bottom: 1px solid #111111;
        padding: 25px 22px;

        &-items {
            &:not(:first-child) {
                padding-left: var(--spaceMenu);
            }
        }

        a {
            color: #111;
            font-size: var(--fsMenu);
        }
    }

    .menu-mobile {
        display: none;
    }

    @media screen and (max-width: 1400px) {
        --fsLang: 20px;
        --fsMenu: 20px;
    }

    @media screen and (max-width: 1030px) {
        --fsMenu: 18px;
        --spaceMenu: 30px;
    }

    @media screen and (max-width: 880px) {
        --fsLang: 16px;

        .header-logo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: var(--paddingLogo);

            .logo {
                max-width: 100px;
                max-height: 36px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .lang {
                img {
                    width: 10px;
                    height: 10px;
                    object-fit: contain;
                }

                span {
                    padding-right: 8px;
                }

                .list-lang {
                    padding: 6px 3px;
                }
            }
        }

        .menu {
            display: none;
        }

        .right-items {
            display: flex;
            align-items: center;
        }

        .menu-mobile {
            display: block;
            margin-left: 15px;

            .mobile-icon {
                width: 30px;
                height: 30px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                .nav-toggle {
                    display: block;
                    width: 20px;
                    height: 15px;
                    position: relative;
                    cursor: pointer;
                    z-index: 99999;
                    span {
                        display: block;
                        width: 100%;
                        height: 2px;
                        border-radius: 3px;
                        background-color: black;
                        transition: all .35s;
                        position: absolute;
                    }

                    span:nth-child(1) {
                        top: 0;
                    }

                    span:nth-child(2) {
                        top: 50%;
                        transform: translateY(-50%)
                    }

                    span:nth-child(3) {
                        bottom: 0;
                    }

                    
                }
                .open{
                    position: fixed; 
                    span:nth-child(1) {
                        top: 50%;
                        transform: translateY(-50%) rotate(45deg);
                        background-color: white;
                    }
                    span:nth-child(2) {
                        display: none;
                    }
                    span:nth-child(3) {
                        bottom: 50%;
                        transform: translateY(50%) rotate(-45deg);
                        background-color: white;
                    }
                }
            }
        }
    }
}

aside {
    display: none;

    @media screen and (max-width: 880px) {
        display: flex;
        position: fixed;
        background-color: #111;
        top: 0;
        transition: 400ms all;
        width: 375px;
        height: 100%;
        z-index: 33333;
        padding: 25px 20px;

        .icon-close {
            font-size: 32px;
            color: #fff;
            font-family: $arialMTBold;

        }

        .menu-mobile-list {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding-top: 60px;

            .menu-mobile-items {
                padding: 20px 0;
            }

            a {
                text-transform: capitalize;
                color: #fff;
                font-size: 22px;

            }

        }
    }
}

.openMenu {
    right: 0
}

.closeMenu {
    right: -375px
}