@import "../../Assets//Styles/variables";

.news-page {
    --fsTit: 54px;
    --paddingTitle: 100px 0 80px 0;
    width: 100%;
    padding: 0 0px 100px;

    .news-title {
        padding: var(--paddingTitle);
        text-align: center;

        h2 {
            font-size: var(--fsTit);
            font-family: $arialMTBold;
        }
    }

    .news-menu {
        max-height: 1450px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        overflow-y: hidden;

        .itemNews {
            border: 0.1px solid #070707;
            display: flex;
            flex-direction: column;

            // background-color: #070707;
            .contentImg {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                padding: 20px 30px;
                cursor: pointer;
                background-color: #fff;

                .title {
                    font-family: $arialMT;
                    font-size: 20px;
                    color: #0d0d0d;
                    white-space: pre-line;
                    line-height: 1.5;
                }

                .date {
                    padding-top: 20px;
                    font-size: 18px;
                    font-family: $arialMTBold;
                }

                img {
                    width: 45px;
                    height: 45px;
                    object-fit: cover;
                }
            }
        }
    }

    .news-menu-full {
        max-height: 100%;
    }

    .news-more {
        margin-top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button-more {
            cursor: pointer;
            border-bottom: 1px solid #111;
            padding: 5px 20px;
            display: inline-flex;
            align-items: center;

            span {
                padding-right: 10px;
                font-size: 18px;
                font-family: $notoSans;
            }

            img {
                width: 13px;
                height: 13px;
                object-fit: cover;
            }
        }
    }

}

@media screen and (max-width: 1300px) {
    .news-page {

        .news-menu {
            grid-template-columns: 1fr;
        }

        .contentImg {
            padding: 10px 20px;

            .title {
                font-size: 16px;
            }

            .date {
                font-size: 14px;
            }
        }


    }
}

@media screen and (max-width: 880px) {
    .news-page {

        .news-menu {
            grid-template-columns: 1fr;
            .itemNews{

                .contentImg {
                    display: flex;
                    flex-direction: column;
                    align-items: unset;
                    padding: 15px 15px;
                    .title{
                        font-size: 16px;
                    }
                    .date{
                        font-size: 12px;
                    }
                    .contentImg-right{
                        display: flex;
                        justify-content: flex-end;
                        img{
                            width: 35px;
                            height: 35px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 660px) {}

@media screen and (max-width: 500px) {}