@import "./fonts";


$arialMTBold: "Arial-BoldMT";
$arialMT: "ArialMT";
$notoSans: "NotoSans";
$notoSansLight: "NotoSansLight";
$nanumBold: "NanumBold";
$nanumLight: "NanumLight";


$maxWidth: 1920px;

$bg-lang-active: #f1589c;
$white: #fff;




