@import "../../Assets//Styles/variables";

.detail-page {
    --spaceBannerInfo: 50px 0 150px;
    --spaceInformation: 40px 0 140px;
    --spaceCreator: 40px 0 100px;
    --spaceDetailCreator: 60px 0 15px;
    --fsName: 40px;
    --fsInfoTit: 50px;
    --fsInfoDetail: 34.5px;
    --fsInformationTit: 22px;
    --fsInfomationDetail: 25px;
    --fsDetailImg: 18px;
    --gridMenu: 30% auto;
    width: 100%;
    padding: 100px 30px;
    &-banner {
        .banner-img {
            display: grid;
            grid-template-columns: var(--gridMenu);
            column-gap: 20px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &-left {
                display: grid;
                height: 100%;
                grid-template-rows: 49% 48.9%;
                row-gap: 20px;
            }
        }

        .banner-info {
            display: grid;
            grid-template-columns: var(--gridMenu);
            column-gap: 20px;
            padding: var(--spaceBannerInfo);
            font-family: $arialMTBold;
            .info-name {
                font-size: var(--fsName);
                text-transform: uppercase;
            }
            .info-detail {
                .title {
                    font-size: var(--fsInfoTit);
                    text-transform: uppercase;
                }
                .detail {
                    font-size: var(--fsInfoDetail);
                    font-family: $nanumBold;
                    padding-top: 20px;
                }
            }
        }

     

    }

    .information {
        display: grid;
        grid-template-columns: var(--gridMenu);
        column-gap: 20px;
        padding: var(--spaceInformation);
        border-top: 1px solid #111;
        &-name {
            font-size: var(--fsName);
            font-family: $arialMTBold;
            text-transform: uppercase;
        }
        &-detail {
            .title {
                font-size: var(--fsInformationTit);
                font-family: $nanumLight;
                line-height: 1.7;
            }
            .subtitle {
                padding-top: 35px;
                font-size: var(--fsInfomationDetail);
                font-family: $arialMT;
                line-height: 1.6;
            }
        }
    }

    .creator {
        display: grid;
        grid-template-columns: var(--gridMenu);
        column-gap: 20px;
        padding: var(--spaceCreator);
        border-top: 1px solid #111;
        &-name {
            font-size: var(--fsName);
            font-family: $arialMTBold;
            text-transform: uppercase;
        }
        &-detail {
            .infoCreator {
                .title {
                    font-family: $arialMTBold;
                    font-size: var(--fsName);
                    text-transform: uppercase;
                }
                .subtitle {
                    font-family: $nanumBold;
                    font-size: var(--fsName);
                    padding-top: 20px;
                }
                .detail {
                    font-size: var(--fsInformationTit);
                    font-family: $nanumLight;
                    padding: var(--spaceDetailCreator);
                    line-height: 1.8;
                    padding-right: 30px;
                }
                .list-img {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    column-gap: 30px;
                }
                .grid-img {
                    grid-template-columns: 100%;
                }
                p {
                    font-size: $nanumBold;
                    padding-top: 5px;
                    font-size: var(--fsDetailImg);
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &:not(:first-child) {
                    margin-top: 120px;
                }
            }
        }
    }
}