@import "./Assets/Styles/variables";
.App {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
}

