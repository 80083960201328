@import "../../../Assets/Styles/styles.scss";

.herosection {
  --paddingContainer: 100px 30px;
  --fsSlideInfo: 30px;
  --fsSubtitle: 24px;
  --fsTitle: 54px;
  --spaceVerticalTitle: 90px;
  --fsH5: 18px;
  border-bottom: 1px solid #111;
  font-family: $arialMT;
  height: auto;
  display: flex;
  justify-content: center;
  white-space: pre-wrap;
  .herosection-container {
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 5%;

    .herosection-grid {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 4fr;

      .top-title {
        display: flex;
        width: 100%;
        padding: 112px 0px 0px 32px;
        font-size: var(--fsSubtitle);

        .top-info {
          width: 100%;
          display: flex;

          .left-info {
            .title {
            }

            .navigation-custom {
              display: flex;
              align-items: center;
              top: 80px;
              left: 0;
              margin-top: 285px;
              margin-bottom: 19px;

              img {
                height: 21px;
              }

              .next-arrow {
                padding-left: 10px;
                cursor: pointer;
              }

              .prev-arrow {
                cursor: pointer;
              }

              .activeArrow {
                opacity: 0.5;
                cursor: default;
              }
            }
          }

          .mid {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .mid-info {
              display: flex;
              flex-direction: column;
              font-family: $arialMTBold;

              .mid-title {
                font-size: var(--fsTitle);
                line-height: 1.2;
              }

              .mid-more {
                padding-top: var(--spaceVerticalTitle);

                a {
                  font-size: var(--fsH5);
                  text-decoration: underline;
                }
              }
            }

            .mid-info-2 {
              font-family: $arialMTBold;
              font-size: var(--fsTitle);
            }
          }

          .end-info {
            font-size: var(--fsSlideInfo);
            width: 100%;
            display: flex;
            justify-content: flex-end;
          }
        }
      }

      .mid-box {
        display: flex;
        width: 100%;
        padding: 112px 0px 0px 0px;
        font-size: var(--fsSubtitle);

        .mid {
          display: flex;
          width: 100%;
          justify-content: space-between;

          .mid-info {
            display: flex;
            flex-direction: column;
            font-family: $arialMTBold;

            .mid-title {
              font-size: var(--fsTitle);
              line-height: 1.2;
            }

            .mid-more {
              padding-top: var(--spaceVerticalTitle);

              a {
                font-size: var(--fsH5);
                text-decoration: underline;
              }
            }
          }

          .mid-info-2 {
            font-family: $arialMTBold;
            font-size: var(--fsTitle);
          }
        }

        .end-info {
          font-size: var(--fsSlideInfo);
          width: 10%;
          margin-left: 15%;
        }
      }

      .bottom-left {
        position: relative;
        width: 100%;
        padding-bottom: 20%;
        overflow: hidden;
        max-height: 570px;
        padding-left: 32px;
        .slide-detail {
          .slide {
            .mySwiper {
              max-height: 570px;
              img {
              }
            }

            .swiper-slide {
              height: 40%;
            }

            .swiper-slide {
              opacity: 0.4;
              cursor: pointer;
            }

            .swiper-slide-thumb-active {
              opacity: 1;
            }

          }
        }
      }

      .bottom-right {
        position: relative;
        width: 100%;
        padding-bottom: 20%;
        overflow: hidden;
        max-height: 570px;
        padding-right: 32px;

        .slide-detail {
          .slide {
            .mySwiper {
              img {}

            }
          }
        }
      }
    }
  }

}


@media screen and (max-width: 1919px) {
  .herosection {
    .herosection-grid {



      .bottom-left {
        display: none;
      }

      .bottom-right {
        grid-column: 1 / span 2;
        position: relative;
        width: 100%;
        height: auto;
        padding-bottom: 50%;
        overflow: hidden;

        padding-right: 32px;
        padding-left: 32px;

        .slide-detail {
          .slide {
            .mySwiper {
              img {}

            }
          }
        }
      }
    }
  }
}

// mobile
@media screen and (max-width: 1300px) {
  .herosection {
    --fsSlideInfo: 20px;

    --fsTitle: 44px;

    .herosection-container {
      .herosection-grid {
        .top-title {
          .top-info {
            .left-info {
              .navigation-custom {
                margin-top: 180px;
              }
            }
          }
        }

        .mid-box {
          .mid {
            width: 80%;
          }
        }

        .bottom-right {
          padding-bottom: 10%;
        }
      }
    }
  }
}

@media screen and (max-width: 880px) {

  .herosection {
    --fsSlideInfo: 20px;
    --fsTitle: 24px;
    --fsSubtitle: 24px;

    .herosection-container {

      .herosection-grid {


        .top-title {
          padding: 30px 0px 0px 16px;
        }

        .mid-box {
          display: flex;
          // flex-direction: column;
          justify-content: space-between;
          padding: 30px 16px 0px 16px;

          .end-info {
            margin-left: unset;
            width: 10%;
          }
        }

        .navigation-custom {
          .prev-arrow {
            img {

              height: 12px;
            }
          }

          .next-arrow {
            img {

              height: 12px;
            }
          }
        }

        .bottom-right {
          padding-right: 16px;
          padding-left: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .herosection {
    .herosection-container {
      .herosection-grid {
        .top-title {
          padding: 30px 0px 0px 15px;
        }

        .mid-box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 30px 16px 0px 15px;
          .mid{
            width: 100%;
            .mid-info-2{
              display: flex;
              justify-content: flex-end;
              padding-left: 15px;
              width: 100%;
            }
          }
          .end-info {
            margin-left: unset;
            width: 100%;
            display: flex;
            padding-bottom: 19px;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}