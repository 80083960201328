@import "../../../Assets/Styles/styles.scss";

.subscribe {
    --paddingMain: 60px 32px;
    --fsTitle: 48px;
    --fsTitleFollow: 24px;
    --spaceTitle: 120px;
    --paddingInput: 35px;
    --marginInput: 150px;
    --widthInput: 350px;
    --widthButton: 280px;
    --paddingButton: 17px 0;
    --iconWidthHeight: 54px;

    &-container {
        padding: var(--paddingMain);
        display: flex;
        align-items: center;
        background-color: #111;
        color: #fff;
        justify-content: space-between;
        .title {
            display: flex;
            align-items: center;

            h2 {
                padding-right: var(--spaceTitle);
                font-size: var(--fsTitle);
                line-height: 1.2;
                color: #fff;
                font-family: $arialMT;
            }
        }

        .follow {
            display: flex;
            align-items: center;

            &-title {
                font-size: var(--fsTitleFollow);
            }

            .list-icon {
                display: flex;
                align-items: center;

                &-item {
                    width: var(--iconWidthHeight);
                    height: var(--iconWidthHeight);
                    margin-left: 30px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            .list-button {
                display: flex;
                align-items: center;
            }

            .email-input {
                padding: --paddingInput;
                background-color: #fff;
                margin-left: var(--marginInput);

                display: flex;
                align-items: center;
                justify-content: center;

                // border-radius: 4px;
                input {
                    border: none;
                    padding: 22px 22px;
                    width: var(--widthInput);

                    &:focus {
                        outline: none;
                    }
                }

                ::placeholder {
                    // text-align: center;
                    color: gray;
                    font-size: 16px;
                }

            }

            .button-confirm {
                margin-left: 20px;
                border: 1px solid #fff;
                width: var(--widthButton);
                padding: var(--paddingButton);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 3px;
            }
        }
    }

    @media screen and (max-width: 1800px) {
        --fsTitle: 38px;
        --spaceTitle: 60px;
        --marginInput: 70px;
        --widthInput: 300px;
        --widthButton: 250px;
        --paddingButton: 17px 0;
    }

    @media screen and (max-width: 1500px) {
        --spaceTitle: 60px;
        --marginInput: 70px;
        --widthInput: 200px;
        --widthButton: 150px;
        // --paddingButton: 12px 0;
        --fsTitle: 32px;
    }

    @media screen and (max-width: 1300px) {
        --paddingMain: 50px 32px;
        --fsTitle: 28px;
        --fsTitleFollow: 20px;
        --iconWidthHeight: 40px;
    }

    @media screen and (max-width: 1150px) {
        --paddingMain: 44px 28px;
        --fsTitle: 22px;
        --fsTitleFollow: 18px;
        --spaceTitle: 30px;
        --marginInput: 30px;
        --widthButton: 150px;
        --iconWidthHeight: 30px;
        --widthInput: 150px;

        .title {
            display: flex;
            align-items: center;
            flex-direction: column;

            .follow-title {
                width: 100%;
            }
        }

        .email-input {
            input {

                padding: 10px 10px;
            }
        }
    }

    @media screen and (max-width: 880px) {
        --fsTitle: 38px;
        --fsTitleFollow: 24px;

        .title {
            width: 100%;
            flex-direction: column;
            align-content: flex-start;
            margin-bottom: 30px;

            h2 {
                width: 100%;
            }
        }

        .follow {
            width: 100%;
            flex-direction: column;
            align-content: flex-start;

            .list-icon {
                margin-bottom: 30px;
                gap:30px;
                .list-icon-item{
                    margin-left: unset;
                }
            }

            .list-button {
                .email-input {
                    margin-left: 0px;

                    input {
                        border: none;
                        padding: 22px 12px;
                    }
                }
            }
        }

        .subscribe-container {
            flex-direction: column;
        }
    }
}