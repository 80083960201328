@import "../../Assets/Styles/variables";

.contact-page {
    width: 100%;
    padding: 84px 30px 0;

    .contact-form {
        display: grid;
        grid-template-columns: 60% 40%;

        .contact-info {
            .info {
                .subtitle {
                    font-size: 24px;
                    font-family: $arialMT;
                }

                .title {
                    font-size: 54px;
                    font-family: $arialMTBold;
                    padding: 30px 0 50px;
                    text-transform: uppercase;
                }

                .detail {
                    font-family: $notoSans;
                    font-size: 16px;
                    line-height: 1.8;
                }
            }

            .address {
                padding-top: 100px;

                .infoAddress {
                    padding-top: 60px;

                    &-title {
                        font-size: 18px;
                        font-family: $arialMT;
                        padding-bottom: 12px;
                    }

                    &-detail {
                        font-size: 18px;
                        font-family: $arialMTBold;
                        white-space: pre-line;
                    }
                }
            }
        }

        .form {
            .infoForm {
                .name {
                    font-size: 18px;
                    font-family: $arialMTBold;
                }

                .detail {
                    padding: 9px 0 30px;
                    font-size: 16px;
                    font-family: $notoSansLight;
                }

                .form-input {

                    input,
                    textarea {
                        border: none;

                        &:focus {
                            outline: none;
                        }

                        &::placeholder {
                            font-family: $notoSansLight;
                        }
                    }

                    textarea {
                        resize: none;
                    }

                    padding: 15px 30px 15px;
                    border: 1px solid #111
                }

                &:not(:first-child) {
                    margin-top: 50px;
                }
            }

            .button-form {
                margin-top: 30px;

                display: flex;
                justify-content: flex-end;
                align-items: center;

                .form-submit {
                    width: 340px;
                    background-color: #111;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 0;
                    cursor: pointer;
                }
            }
        }
    }

    .contact-social {
        margin-top: 100px;
        display: grid;
        grid-template-columns: 50% auto;

        .social-info {
            .subtitle {
                font-size: 24px;
                font-family: $arialMT;
                padding-bottom: 30px;
            }

            .title {
                text-transform: uppercase;
                display: flex;
                flex-direction: column;
                font-size: 54px;
                font-family: $arialMTBold;

                a {
                    color: #111;

                    &:not(:first-child) {
                        padding-top: 40px;
                    }
                }
            }
        }

        .social-img {
            max-height: 719px;

            // width: 80%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }


    @media screen and (max-width: 1300px) {


        .contact-form {
            display: grid;
            grid-template-columns: 1fr;

            .contact-info {
                margin-bottom: 100px;

                .address {
                    padding-top: 50px;
                }
            }
        }

        .contact-social {
            grid-template-columns: 1fr;
            margin-top: 60px;
            margin-bottom: 30px;

            .social-info {
                margin-bottom: 30px;
            }
        }

    }

    @media screen and (max-width: 660px) {

        padding: 30px 12px 0;

        .contact-info {
            .title {
                p {
                    font-size: 30px;
                }
            }

            .detail {
                font-size: 12px;
            }

            .address {
                padding-top: 0px;
            }

        }
    }

    @media screen and (max-width: 500px) {}
}