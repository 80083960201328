footer {
   width: 100%;
   font-size: 30px;
   display: flex;
   align-items: center;
   justify-content: center;

   .footer-container {
      width: 100%;

      .footer-warpper {
         width: 100%;
         display: flex;
         flex-direction: column;
         font-family: "NotoSansLight";
         font-weight: normal;

         .line {
            display: flex;
            justify-content: center;
         }

         .mid {
            padding: 13px 0;
            font-size: 14px;
            gap: 50px;
            border-top: 1px solid black;
            border-bottom: 1px solid black;
         }

         .bottom {
            padding: 20px 0;
            font-size: 14px;
            color: #fff;
            background-color: black;
         }
      }
   }

   @media screen and (max-width: 920px) {
      .footer-container {
         .footer-warpper {
            .mid {
               font-size: 10px;
               gap: 30px;
            }

            .bottom {
               font-size: 10px;
               padding: 20px 10px;
            }
         }
      }
   }
   @media screen and (max-width: 600px) {
      .footer-container {
         .footer-warpper {
            .mid {
               display: flex;
               justify-content: center;
               flex-direction: column;
               padding: 20px 10px;
               gap: 10px;
               font-size: 15px
            }
            .bottom {
               font-size: 10px;
               padding: 20px 10px;
            }
         }
      }
   }
}