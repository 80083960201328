@import "../../../Assets/Styles/styles.scss";

.exhibition {
    --fsMain: 24px;
    --fsTitle: 54px;
    --fsSubTitle: 24px;
    --fsDescription: 16px;

    height: auto;
    display: flex;
    justify-content: center;

    .exhibition-container {
        font-family: $notoSans;
        display: flex;
        height: auto;
        align-items: center;
        padding-top: 37.5%;
        position: relative;
        width: 100%;
        max-height: 719px;

        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 100%;
            max-height: 719px;
            border-bottom: 1px solid black;

            left: 0;
            top: 0;

            .intro {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 50px 32px 32px 32px;
                height: 100%;
                width: 45%;

                .title {
                    width: 100%;

                    h2 {
                        font-size: var(--fsTitle);
                        font-family: $arialMTBold;
                        line-height: 1.2;
                        margin-bottom: 30px;
                    }

                    .explain {
                        font-size: var(--fsSubTitle);
                        line-height: 1.5;
                        margin-bottom: 30px;
                    }
                }

                .subtitle {
                    font-size: var(--fsMain);
                    margin-bottom: 30px;
                }

                .detail {
                    margin-bottom: 83px;

                    p {
                        font-size: var(--fsDescription);
                        font-family: $notoSansLight;
                        line-height: 1.7;
                    }
                }

                .arrow-right {
                    width: 64px;
                    height: auto;
                    margin-bottom: 56px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .navigation-custom {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    bottom: 50px;
                    left: -100px;

                    img {
                        height: 30px;
                        object-fit: cover;
                    }

                    .next-arrow {
                        padding-left: 30px;
                        cursor: pointer;
                    }

                    .prev-arrow {
                        cursor: pointer;
                    }

                    .activeArrow {
                        opacity: 0.5;
                        cursor: default;
                    }
                }
            }

            .slide {
                width: 53.67%;
                height: 100%;

                .mySwiper-exhibition {
                    width: 100%;
                    height: 100%;
                    img {
                        width: 100%;
                    }
                    .swiper {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;

                        .swiper-slide img {
                            width: 100% !important;
                            height: auto;
                            max-height: 719px;
                        }
                    }
                }
            }

        }
    }

    @media screen and (max-width: 1800px) {
        --fsMain: 18px;
        --fsTitle: 44px;
        --fsSubTitle: 18px;
        --fsDescription: 14px;
    }

    @media screen and (max-width: 1500px) {
        --fsMain: 16px;
        --fsTitle: 34px;
        --fsSubTitle: 16px;
        --fsDescription: 12px;
    }

    @media screen and (max-width: 1300px) {
        --fsMain: 16px;
        --fsTitle: 24px;
        --fsSubTitle: 16px;
        --fsDescription: 12px;
    }

    @media screen and (max-width: 900px) {
        --fsMain: 10px;
        --fsTitle: 14px;
        --fsSubTitle: 10px;
        --fsDescription: 8px;
    }
}

@media screen and (min-width : 1919px) {
    .exhibition {
        height: 719px;
    }
}

// pc screen
@media screen and (max-width: 1800px) {
    .exhibition {
        .exhibition-container {
            .content {
                .intro {
                    .title {
                        width: 100%;

                        h2 {
                            font-family: $arialMTBold;
                            line-height: 1.2;
                            margin-bottom: 30px;
                        }

                        .explain {
                            line-height: 1.5;
                            margin-bottom: 30px;
                        }
                    }

                    .subtitle {
                        margin-bottom: 30px;
                    }

                    .detail {
                        margin-bottom: 50px;

                        p {}
                    }

                    .arrow-right {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1500px) {
    .exhibition {
        .exhibition-container {
            .content {
                .intro {
                    .subtitle {
                        margin-bottom: 15px;
                    }

                    .title {
                        width: 100%;

                        h2 {
                            font-family: $arialMTBold;
                            line-height: 1.2;
                            margin-bottom: 15px;
                        }

                        .explain {
                            line-height: 1.5;
                            margin-bottom: 15px;
                        }
                    }

                    .detail {
                        margin-bottom: 50px;

                        p {}
                    }

                    .arrow-right {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

// mobile
@media screen and (max-width: 1300px) {
    .exhibition {

        .exhibition-container {
            .content {
                .intro {
                    padding: unset;
                    padding: 25px 16px 16px 16px;

                    .title {
                        .subtitle {
                            margin-bottom: 5px;
                        }

                        h2 {
                            margin-bottom: 5px;
                        }

                        .detail {
                            margin-bottom: 5px;
                        }

                        .explain {
                            margin-bottom: 5px;
                        }

                        .arrow-right {
                            margin-bottom: 5px;
                        }

                        .arrow-right {
                            width: 32px;
                            height: auto;
                        }
                    }

                    .navigation-custom {
                        .prev-arrow {
                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }

                        .next-arrow {
                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }

                    }
                }
            }
        }
    }
}

@media screen and (max-width: 880px) {
    .exhibition {
        --fsMain: 24px;
        --fsTitle: 44px;
        --fsSubTitle: 24px;
        --fsDescription: 16px;

        .exhibition-container {
            font-family: "NotoSans";
            display: flex;
            align-items: unset;
            position: unset;
            width: 100%;
            padding-top: unset;
            max-height: unset;

            .content {
                display: flex;
                justify-content: unset;
                align-items: unset;
                position: unset;
                width: 100%;
                height: 100%;
                border-bottom: 1px solid black;
                max-height: unset;
                flex-direction: column;

                .intro {
                    padding: 50px 15px 32px 15px;
                    width: 100%;
                    .title {
                        .subtitle {
                            margin-bottom: 30px;
                        }

                        h2 {
                            margin-bottom: 30px;
                        }

                        .detail {
                            margin-bottom: 30px;
                        }

                        .explain {
                            margin-bottom: 30px;
                        }

                        .arrow-right {
                            margin-bottom: 30px;
                        }

                        .arrow-right {
                            width: 50px;
                            height: auto;
                        }
                    }
                }

                .slide {
                    width: 100%;
                    height: 100%;

                    .mySwiper-exhibition {
                        width: 100%;
                        margin-bottom: -10px;

                        .swiper {
                            position: unset;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;

                            .swiper-slide img {
                                width: 100%;
                                height: auto;

                            }
                        }
                    }
                }
            }
        }
    }
}